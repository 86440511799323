.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url("../../assets/home-bg.jpg") no-repeat center center fixed;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
}   

.home-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-image img {
    max-width: 70%;
    max-height: 70%;
}

@media (max-width: 767px) {
    .home {
        background-attachment: initial;
    }
}
