.specials_bg {
    background: url("../../assets/specials-bg.jpg") no-repeat center center fixed;
    background-attachment: fixed;
    background-size: cover;
    height: 80vh;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5%;
    padding-top: 5%;
}

.specials_container {
    align-self: center;
    position: relative;
    width: 40vw;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 40px;
    align-items: left;
    align-content: center;
    padding: 4ch;

    display: flex;
    flex-direction: column;
}

.specials_title {
    color: white;
    font-family: var(--font-family);
    text-decoration: underline;
    text-underline-offset: 20%;
    text-align: center;
    font-size: 36px;
    letter-spacing: 2px;
    padding-bottom: 2ch;
}

.specials_container p {
    color: white;
    font-family: var(--font-family);
    text-align: center;
    font-size: 32px;
}

@media (max-width: 1000px) { 
    .specials_title {
        font-size: 32px;
    }

    .specials_container p {
        font-size: 28px;
    }
}

@media (max-width: 769px) {
    .specials_bg {
        background-attachment: initial;
    }
    
    .specials_title {
        font-size: 28px;
    }

    .specials_container p {
        font-size: 24px;
    }
} 

@media (max-width: 642px) {
    .specials_container {
        width: 60vw;
    }
} 

@media (max-width: 680px) {
    .specials_container {
        width: 80vw;
    }
} 
