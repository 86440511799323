.services_bg {
    background: url("../../assets/services-bg.jpg") no-repeat center center fixed;
    background-attachment: fixed;
    background-size: cover;
    height: fit-content;
    
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
    padding-top: 5%;
}

.services_container {
    align-self: center;
    position: relative;
    width: 60vw;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 40px;
    align-items: left;
    align-content: center;
    padding: 4ch;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.services_title {
    color: white;
    font-family: var(--font-family);
    text-decoration: underline;
    text-underline-offset: 20%;
    text-align: center;
    font-size: 36px;
    letter-spacing: 2px;
    padding-bottom: 2ch;
    width: 100%;
}

.services_column {
    color: white;
    font-family: var(--font-family);
    font-size: 32px;
    padding-bottom: 1%;
    padding-left: 7%;
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    width: 50%;
}

.prices_column {
    color: white;
    font-family: var(--font-family);
    font-size: 32px;
    padding-right: 7%;
    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: right;
    width: 50%;
    text-align: right;
}

.prices_break {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 5%;
}

.standard_break {
    width: 100%;
    padding-bottom: 3%;
}

.service_category {
    text-decoration: underline;
    text-underline-offset: 20%;
    padding-bottom: 2%;
    padding-top: 5%;
}

.services_column div {
    width: 150%;
}

@media (max-width: 1725px) {
    .prices_column {
        font-size: 26px;
    }

    .services_column {
        font-size: 26px;
    }
}

@media (max-width: 1410px) {
    .prices_column {
        padding-right: 0;
    }

    .services_column {
        padding-left: 0;
    }
}

@media (max-width: 1245px) {
    .prices_column {
        font-size: 24px;
    }

    .services_column {
        font-size: 24px;
    }
}

@media (max-width: 1155px) {
    .services_container {
        width: 80vw;
    }
}

@media (max-width: 675px) {
    .services_bg {
        background-attachment: initial;
    }
}

@media (max-width: 860px) {
    .prices_column {
        font-size: 20px;
    }

    .services_column {
        font-size: 20px;
    }
}

@media (max-width: 730px) {

    .services_title {
        font-size: 24px;
    }
}

@media (max-width: 525px) {
    .services_container {
        width: 90vw;
        padding-left: 2ch;
        padding-right: 2ch;
    }

    .services_column {
        font-size: 20px;
    }
    
    .prices_column {
        font-size: 20px;
    }
}

@media (max-width: 410px) {
    .services_column {
        font-size: 16px;
    }
    
    .prices_column {
        font-size: 16px;
    }
}

@media (max-width: 345px) {
    .services_column {
        font-size: 12px;
    }
    
    .prices_column {
        font-size: 12px;
    }
}
