.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 1%;
    background-color: rgba(0, 0, 0, 0.62);
    width: 100%;
    height: 69px;
    z-index: 100;
}

.navbar_links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar_logo {
    margin-right: 2rem;
}

.navbar_logo img {
    width: 127.5px;
    height: 53.4375px;
}

.navbar_links_container {
    display: flex;
    flex-direction: row;
}

/* This is for underlining when hovering */
.navbar_links_container > p:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.navbar_links_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

@media (max-width: 769px) {
    .navbar_logo,
    .navbar_logo img {
        display: none;
    }

    .navbar_links {
        justify-content: center;
        padding-right: 2%;
    }
}

@media (max-width: 480px) {
    .navbar_links_container p {
        font-size: 14px;
    }

    .navbar {
        height: 55px;
    }
}
