.gallery_background {
    background: url("../../assets/matte-black.jpg") no-repeat center center fixed;
    height: fit-content;
    background-size: cover;
    background-attachment: fixed;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
    height: fit-content;
    min-height: 100vh;
}

.gallery_title {
    color: white;
    font-family: var(--font-family);
    text-underline-offset: 20%;
    text-decoration: underline;
    text-align: center;
    font-size: 250%;
    letter-spacing: 2px;
    padding-top: 3ch;
    padding-bottom: 2ch;
}

.gallery_container {
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 40px;
    padding-top: 2%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;

}

.gallery_container img {
    border-radius: 10px;
    margin-bottom: 3%;
    width: 22%;
    height: 40%;
}

@media (max-width: 1200px) {
    .gallery_container {
        border-radius: 30px;
    }

    .gallery_background {
        padding-top: 5%;
    }
}

@media (max-width: 768px) {
    .gallery_background {
        background-attachment: initial;
    }
    
    .gallery_container {
        padding: 4%;
        padding-top: 6%;
    }

    .gallery_container img {
        width: 33%;
        height: 60%;
    }
}

@media (max-width: 481px) {
    .gallery_container img {
        width: 41.25%;
        height: 75%;
    }

    .gallery_container {
        width: 90vw;
    }
}
