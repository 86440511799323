.about_background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    background: url("../../assets/white-marble.jpg") no-repeat center center fixed;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
}

.about_background body {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 40px;
}

.about_background img {
    float: right;
    margin: 10px;
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
    width: 35%;
    height: 35%;
    border-radius: 10px;
}

.about_background p {
    color: white;
    text-align: left;
    font-size: 2rem;
    font-family: var(--font-family);
    padding-left: 4%;
    padding-bottom: 4%;
    padding-right: 4%;
}

.about_title {
    padding: 2%;
    margin-left: 2%;
    text-align: left;
    font-family: var(--font-family);
    color: white;
    text-decoration: underline;
    text-underline-offset: 10px;
    font-size: 2rem;
}

.about_buttons {
    margin: 5%;
    width: fit-content;
    height: 10vh;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 40px;
    position: relative;
    
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.location_icon {
    color: white;
    font-size: 3rem;
}

@media (max-width: 769px) { 
    .about_background {
        background-attachment: initial;
    }
    
    .about_background p {
        font-size: 1rem;
    }

    .about_background body {
        border-radius: 30px;
    }

    .about_title {
        font-size: 1rem;
        text-underline-offset: 5px;
    }
}

@media (max-width: 480px) {
    .about_background img {
        border-radius: 5px;
    }

    .about_background body {
        border-radius: 20px;
    }
} 
